<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
        ><i class="el-icon-refresh-right"></i> 刷新
        </el-button>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="mtrTab">
            <el-table-column label="序号" width="50px" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="create_time" width="150px" :formatter="formatDate"/>
            <el-table-column label="文本" prop="docu_msg" width="auto">
              <template v-slot="scope">
                <el-tooltip popper-class="item" effect="dark" placement="top">
                  <div slot="content" class="item">
                    {{ scope.row.docu_msg }}
                  </div>
                  <span
                      style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    "
                  >{{ scope.row.docu_msg }}</span
                  >
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="140px">
              <template slot-scope="scope">
                <div style="display: flex;justify-content: center">
                  <el-link
                      class="vg_mr_8 vg_cursor"
                      type="primary"
                      size="mini"
                      @click="goRequList(scope.row)"
                  >查看详情
                  </el-link>
                  <!--                  <el-link class="vg_cursor" type="success" size="mini" @click="goRequList(scope.row)">已处理</el-link>-->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {getNoCatch, postNoCatch} from '@api/request';
import {requAPI} from '@api/modules/requ';
import bus from '@/components/common/bus';

export default {
  name: 'MailPush9003',
  data() {
    return {
      tableData: [],
      loading: true
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    bus.$on('refreshPush9007', msg => {
      this.initData();
    });
  },
  methods: {
    initData() {
      this.getRequPush();
    },
    //获取数据
    getRequPush() {
      getNoCatch(requAPI.get_docu_push_9007).then(({data}) => {
        this.tableData = data;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    //跳转RequList页面
    goRequList(row) {
      //退单提醒
      postNoCatch(requAPI.edit_docu_push_9007, {id: row.id}).then(({data}) => {
        this.jump('/requ_edit', {perm_id: row.perm_id, form_id: row.form_id})
        this.initData();
        bus.$emit('refreshReminderCount');
      });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toTime13(row.create_time);
    },
    // 刷新按钮
    buttonRefresh() {
      this.getRequPush();
      this.loading = true;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}

.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
